<template>
  <div class="home">
    <Header/>
    <InfoBar/>
    <v-container>
      <v-row class="mt-5 mb-5">
        <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="8" offset-xl="2">
          <v-row class="text-left">
            <v-col cols="12" class="pt-0 pb-0">
              <h1 class="home-title">{{$t('home.goal.title')}}</h1>
            </v-col>
            <v-col cols="12">
              <p>
                <span style="max-width: 800px;display: inline-block;">{{$t('home.goal.paragraph1')}}</span>
              </p>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <p>
                <span style="max-width: 800px;display: inline-block;">{{$t('home.goal.paragraph2')}}</span>
              </p>
            </v-col>
            <v-col cols="12">
              <p>
                <span style="max-width: 800px;display: inline-block;">{{$t('home.goal.paragraph3')}}</span>
              </p>
            </v-col>
            <v-col cols="12" class="pb-0" id="news">
              <h1 class="home-title">SOCIAL <span class="home-title-primary">NEWS</span></h1>
              <div class="home-subtitle">
                <a href="https://www.instagram.com/ebikeworldfederation/" target="_blank">@ebikeworldfederation</a>
                <a href="https://www.instagram.com/explore/tags/ebikeworldfederation/" target="_blank">
                  #ebikeworldfederation
                </a>
              </div>
            </v-col>
            <v-col cols="12" class="pb-0" id="world">
              <h1 class="home-title">
                {{$t('home.discoverOur')}}
                <span class="home-title-primary">{{$t('home.world')}}</span>
              </h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="10" offset-lg="1" class="home-world-container">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-card @click="goToWM()">
                <v-img height="234"
                       src="https://ebikeworldfederation.com/images/EBFEDERATION_Teaserbild_01.jpg"/>
                <v-card-title class="home-gray">
                  <h1 class="home-title">{{$t('home.project.wm.title')}}</h1>
                </v-card-title>
                <v-card-text class="home-gray">
                  <v-row class="home-world-container-text">
                    <v-col cols="12">
                      {{$t('home.project.wm.description')}}
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <div>
                        <v-icon color="#444444">{{icons.calendar}}</v-icon>
                        {{$t('home.project.wm.date')}}
                      </div>
                      <div>
                        <img alt="Flag"
                             v-lazyimg="'https://ebikeworldfederation.com/images/locations/EBWF_Austria.svg'">
                        {{$t('home.project.wm.location')}}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="home-gray">
                  <div>
                    {{$t('home.discoverNow')}}
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </div>
                </v-card-actions>
              </v-card>
              <v-card class="v-card-top-small-offset" @click="goToCup()">
                <v-img height="234"
                       src="https://ebikeworldfederation.com/images/EBWF_Website_Contentbild_07.jpg"/>
                <v-card-title class="home-gray">
                  <h1 class="home-title">{{$t('home.project.cup.title')}}</h1>
                </v-card-title>
                <v-card-text class="home-gray">
                  <v-row class="home-world-container-text">
                    <v-col cols="12">
                      {{$t('home.project.cup.description')}}
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <div>
                        <img alt="Flag"
                             v-lazyimg="'https://ebikeworldfederation.com/images/locations/EBWF_Austria.svg'">
                        {{$t('home.project.cup.location')}}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="home-gray">
                  <div>
                    {{$t('home.discoverNow')}}
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-card class="v-card-top-offset" @click="goToMember()">
                <v-img height="234"
                       src="https://ebikeworldfederation.com/images/EBWF_Website_Contentbild_06.jpg"/>
                <v-card-title>
                  <h1 class="home-title" v-html="$t('home.project.member.title')"/>
                </v-card-title>
                <v-card-text>
                  <v-row class="home-world-container-text">
                    <v-col cols="12">
                      {{$t('home.project.member.description')}}
                    </v-col>
                    <v-col cols="12" class="pb-0">
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div>
                    {{$t('home.discoverNow')}}
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </div>
                </v-card-actions>
              </v-card>
              <v-card class="v-card-top-small-offset" @click="goToShop()">
                <v-img height="234"
                       src="https://ebikeworldfederation.com/images/EBWF_Website_Contentbild_08.jpg"/>
                <v-card-title>
                  <h1 class="home-title">{{$t('home.project.shop.title')}}</h1>
                </v-card-title>
                <v-card-text>
                  <v-row class="home-world-container-text">
                    <v-col cols="12">
                      {{$t('home.project.shop.description')}} <br/>
                      <a @click.stop="goToWirSindEBike" target="_blank">#wirsindebike</a>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div>
                    {{$t('home.discoverNow')}}
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="home-world-social-container">
        <v-col cols="12" class="text-center mt-4">
          <v-card class="social-card-offset" @click="goToFacebook()">
            <img class="info-background-image" alt="Facebook"
                 v-lazyimg="'https://ebikeworldfederation.com/images/social/Facebook_Logo.png'">
          </v-card>
          <v-card class="social-card-offset" @click="goToInstagram()">
            <img class="info-background-image" alt="Instagram"
                 v-lazyimg="'https://ebikeworldfederation.com/images/social/Instagram_Logo.png'">
          </v-card>
          <v-card @click="goToYoutube()">
            <img class="info-background-image" alt="Youtube"
                 v-lazyimg="'https://ebikeworldfederation.com/images/social/Youtube_Logo.png'">
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="home-header-between-container">
      <v-img src="https://ebikeworldfederation.com/images/EBWF_Website_Zwischenheaderbild_01.jpg"
             v-if="$vuetify.breakpoint.smAndUp"/>
      <v-img src="https://ebikeworldfederation.com/images/EBWF_Website_Zwischenheaderbild_01_SMALL.jpg" v-else/>
    </div>
    <div class="home-gray">
      <v-container>
        <v-row class="mt-5 mb-5">
          <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="8" offset-xl="2">
            <v-row class="text-left">
              <v-col cols="12" class="pt-0 pb-0" id="projects">
                <h1 class="home-title">
                  {{$t('home.our')}}
                  <span class="home-title-primary">{{$t('home.projects')}}</span>
                </h1>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="10" offset-lg="1" class="home-world-container">
                <v-row>
                  <v-col cols="12" sm="12" md="6" class="text-right">
                    <v-card @click="goToRidingCenterBlog()">
                      <v-img height="234"
                             src="https://ebikeworldfederation.com/images/EBWF_Website_Contentbild_09.jpg"/>
                      <v-card-title class="home-gray">
                        <h1 class="home-title">{{$t('home.project.center.title')}}</h1>
                      </v-card-title>
                      <v-card-text class="home-gray">
                        <v-row class="home-world-container-text">
                          <v-col cols="12">
                            {{$t('home.project.center.description')}}
                          </v-col>
                          <v-col cols="12" class="pb-0">
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="home-gray">
                        <div>
                          {{$t('home.discoverNow')}}
                          <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                            <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                              C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                              C31.4,8.2,31.4,7.9,31.3,7.7"/>
                          </svg>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="text-left">
                    <v-card class="v-card-top-offset" @click="goToAirstreamBlog()">
                      <v-img height="234"
                             src="https://ebikeworldfederation.com/images/EBWF_Website_Contentbild_10.jpg"/>
                      <v-card-title>
                        <h1 class="home-title">{{$t('home.project.airstream.title')}}</h1>
                      </v-card-title>
                      <v-card-text>
                        <v-row class="home-world-container-text">
                          <v-col cols="12">
                            {{$t('home.project.airstream.description')}}
                          </v-col>
                          <v-col cols="12" class="pb-0">
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <div>
                          {{$t('home.discoverNow')}}
                          <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="8" offset-xl="2">
            <v-row class="text-left">
              <v-col cols="12" class="pt-0 pb-0">
                <h1 class="home-title">
                  {{$t('home.our')}}
                  <span class="home-title-primary">{{$t('home.partner')}}</span>
                </h1>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1" class="pb-12">
            <partners/>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Header from "../components/home/Header";
import InfoBar from "../components/home/InfoBar";
import Partners from "../components/Partners";
import {mdiChevronRight, mdiCalendarBlankOutline} from '@mdi/js'

export default {
  name: 'home',
  data() {
    return {
      icons: {
        right: mdiChevronRight,
        calendar: mdiCalendarBlankOutline,
      },
    };
  },
  components: {
    Header, InfoBar, Partners,
  },
  mounted() {
    this.globalMenuEvents.$on('goToElement', (selector) => {
      if (selector !== undefined) {
        this.$vuetify.goTo(selector, {
          duration: 250,
          offset: 70,
          easing: 'easeInOutCubic',
        });
      }
    });
  },
  methods: {
    goToWM() {
      window.open('https://ebikewm.com', '_blank');
    },
    goToCup() {
      window.open('https://ebikecup.com', '_blank');
    },
    goToWirSindEBike() {
      window.open('https://www.instagram.com/explore/tags/wirsindebike/', '_blank');
    },
    goToInstagram() {
      window.open('https://www.instagram.com/ebikeworldfederation/', '_blank');
    },
    goToYoutube() {
      window.open('https://www.youtube.com/playlist?list=PL8jdDI7jKo3Wg_T9rl3EE5Ddh08Uu7ivE', '_blank');
    },
    goToFacebook() {
      window.open('https://www.facebook.com/ebikeworldfederation/', '_blank');
    },
    goToRidingCenterBlog() {
      window.open('https://specialdeal.at/blog/15661', '_blank');
    },
    goToAirstreamBlog() {
      window.open('https://specialdeal.at/blog/15662', '_blank');
    },
    goToMember() {
      this.$router.push({ name: 'member' });
    },
    getColumns() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1;
      }
      return this.$vuetify.breakpoint.mdAndDown ? 2 : 3;
    },
    goToShop() {
      this.$router.push({ name: 'shop' });
    },
  },
}
</script>

<style lang=scss>
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";
  .home {
    font-family: $default-font;
    .home-gray {
      background-color: $basic-gray;
    }
    .home-title {
      font-weight: 600;
      font-style: italic;
      font-size: 2.2em;
      letter-spacing: 0;
      word-break: keep-all !important;
      .home-title-primary {
        color: $primary;
      }
    }
    .home-subtitle {
      color: $primary;
      font-weight: 600;
      font-style: italic;
      a {
        text-decoration: none;
      }
    }
    .home-world-container {
      .v-card {
        display: inline-block;
        border-radius: 8px;
        width: 100%;
        &.v-card-top-offset {
          margin-top: 80px;
        }
        &.v-card-top-small-offset {
          margin-top: 30px;
        }
        .home-title {
          text-align: left;
          font-size: 35px;
          line-height: 36px;
          @include MQ(M) {
            font-size: 30px;
            line-height: 31px;
          }
        }
        .home-world-container-text {
          text-align: left;
          font-size: 16px;
          color: $primary-text;
          svg {
            height: 16px;
            width: 16px;
            margin-top: -4px;
          }
          img {
            margin-left: 2px;
            margin-right: 4px;
          }
          a {
            text-decoration: none;
          }
        }
        .v-card__text {
          min-height: 128px;
        }
        .v-card__actions {
          padding: 16px;
          div {
            font-weight: 600;
            font-style: italic;
            svg {
              height: 14px;
              margin-left: 5px;
              margin-bottom: -2px;
            }
          }
        }
      }
    }
    .home-world-social-container {
      .v-card {
        display: inline-block;
        border-radius: 8px;
        padding: 10px;
        min-width: 115px;
        &.social-card-offset {
          margin-right: 25px;
        }
        img {
          margin-top: 6px;
          height: 40px;
        }
      }
    }
    .home-header-between-container {
      .v-image {
        height: 650px;
        @include MQ(L) {
          height: 600px;
        }
        @include MQ(M) {
          height: 500px;
        }
        @include MQ(S) {
          height: 350px;
        }
        @include MQ(XS) {
          height: 200px;
        }
      }
    }
    .events-container {
      .v-card {
        .v-image {
          height: 270px;
          @include MQ(M) {
            height: 250px;
          }
          @include MQ(S) {
            height: 250px;
          }
          @include MQ(XS) {
            height: 200px;
          }
        }
        .v-card__title {
          font-weight: 600;
          text-align: left;
          line-height: 20px;
          min-height: 75px;
        }
        .v-card__subtitle {
          font-size: 24px;
          color: $primary-text;
          margin-top: 0;
          font-weight: 600;
          text-align: left;
          font-style: italic;
          min-height: 70px;
          @include MQ(L) {
            min-height: 82px;
          }
          @include MQ(M) {
            font-size: 23px;
            min-height: 82px;
          }
          @include MQ(S) {
            font-size: 21px;
            min-height: 90px;
          }
          @include MQ(XS) {
            font-size: 18px;
            min-height: 0;
          }
        }
        .v-card__actions {
          padding: 16px 16px 30px;
        }
        .v-btn {
          border-radius: 15px;
          border-width: 2px;
          padding: 0 5px;
          height: 30px;
          text-transform: none;
          .v-btn__content {
            padding: 0;
            height: 25px;
          }
        }
      }
    }
    .partners-container {
      text-align: left;
      .federation {
        display: inline-block;
        font-size: 25px;
        font-weight: 600;
        font-style: italic;
        @include MQ(S) {
          display: block;
          font-size: 22px;
          text-align: center;
          margin-bottom: 20px;
        }
        @include MQ(XS) {
          display: block;
          font-size: 22px;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .partner-logo {
        @include MQ(XS) {
          text-align: center;
          display: block;
          margin-bottom: 15px;
        }
        @include MQ(S) {
          text-align: center;
          display: block;
          margin-bottom: 15px;
        }
        .v-image {
          margin-left: 25px;
          max-height: 60px;
          max-width: 250px;
          object-fit: contain;
          object-position: center;
          display: inline-block;
          @include MQ(M) {
            margin-left: 15px;
            max-height: 60px;
            max-width: 200px;
          }
          @include MQ(S) {
            margin-left: 15px;
            max-height: 60px;
            max-width: 200px;
          }
          @include MQ(XS) {
            margin-bottom: 0 !important;
            margin-left: 10px;
            max-height: 60px;
            max-width: 160px;
          }
        }
      }
    }
    .juicer-feed {
      padding-left: 0;
      .referral {
        display: none;
      }
      .juicer-button {
        border-color: $primary;
        color: $primary;
        &:hover {
          background-color: $primary;
          color: white;
        }
      }
      a {
        &:hover {
          color: $primary;
        }
      }
    }

    .home-background-image-wm {
      background-image: url("https://ebikeworldfederation.com/images/EBCUP_Teaserbild_Verlinkung_ebikewm_01.jpg");
      background-position: center;
      height: 600px;
      position: relative;
      @include MQ(M) {
        height: 550px;
      }
      @include MQ(S) {
        height: 400px;
      }
      @include MQ(XS) {
        height: 350px;
      }
      .wm-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        h1 {
          color: white;
          font-weight: 600;
          font-style: italic;
          &.subtitle {
            color: $secondary;
          }
        }
        p {
          color: white;
          margin-bottom: 0;
        }
        .v-btn {
          text-transform: none;
          border-radius: 15px;
        }
      }
    }

    .home-background-image-shop, .home-background-image-member {
      background-image: url("https://ebikeworldfederation.com/images/shop/EBWF_Wirsindebike_Shop_Teaserbild_01.jpg");
      background-position: center;
      background-size: cover;
      height: 600px;
      position: relative;
      @include MQ(M) {
        height: 550px;
      }
      @include MQ(S) {
        height: 400px;
      }
      @include MQ(XS) {
        height: 350px;
      }
      .shop-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        h1 {
          color: white;
          font-weight: 600;
          font-style: italic;
          &.subtitle {
            color: $secondary;
          }
        }
        .v-btn {
          margin-top: 50px;
          text-transform: none;
          border-radius: 15px;
        }
      }
    }
    .home-background-image-member {
      background-image: url("https://ebikeworldfederation.com/images/EBWF_Banner_Mitgliedschaft_102020_01.jpg");
    }
  }
</style>
