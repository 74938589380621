<template>
  <v-row class="home-header-container" :style="[{height: getHeaderHeight()}]">
    <v-col cols="12" class="pa-0">
      <v-carousel :height="getHeaderHeight()" hide-delimiters :show-arrows="false">
        <v-carousel-item
          v-for="(picture,i) in pictures"
          :key="i"
          :src="picture"
        />
      </v-carousel>
      <div class="header-title-container">
        <h1>{{ $t("home.header.title") }}</h1>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'home-header',
    data() {
      return {
        pictures: [
          'https://ebikeworldfederation.com/images/headers/EBFEDERATION_Headerbild_01.jpg',
        ],
        difference: undefined,
        result: false,
      };
    },
    methods: {
      getHeaderHeight() {
        let height = '600px';
        if (this.$vuetify.breakpoint.smAndDown) {
          height = '450px';
        } else if (this.$vuetify.breakpoint.mdOnly) {
          height = '500px';
        }
        return height;
      },
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .home-header-container {
    position: relative;
    z-index:1;
    width: 100%;
    margin: 0 !important;
    .header-title-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: white;
      font-size: 35px;
      font-style: italic;
      text-transform: uppercase;
      text-shadow: 2px 2px 4px #000000;
      margin-top: 100px;
      @include MQ(XS) {
        font-size: 20px;
      }
      @include MQ(S) {
        font-size: 25px;
      }
      @include MQ(M) {
        font-size: 28px;
      }
      @include MQ(L) {
        font-size: 30px;
      }
    }
  }
</style>
