<template>
  <div class="home-info-bar-container">
    <v-container>
      <v-main>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <div class="info-container" @click="openWM()">
                  <div class="info-image-container">
                    <img class="info-background-image" alt="EBWF"
                         v-lazyimg="'https://ebikeworldfederation.com/images/infobar/EBWF_Website_Contentbild_01.jpg'">
                    <img class="info-logo-image" alt="EBWF"
                         v-lazyimg="'https://ebikeworldfederation.com/images/EBWM_Website_Logo_2020.png'">
                  </div>
                  <div class="info-text-container">
                    <div class="info-text-container-title">
                      {{$t('home.project.wm.title')}}
                    </div>
                    <div class="info-text-container-date">
                      <v-icon color="#444444">{{icons.calendar}}</v-icon>
                      {{$t('home.project.wm.date')}}
                    </div>
                    <div class="info-text-container-location">
                      <img class="info-text-container-location-flag" alt="Flag"
                           v-lazyimg="'https://ebikeworldfederation.com/images/locations/EBWF_Austria.svg'">
                      {{$t('home.project.wm.location')}}
                    </div>
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve" class="info-text-container-arrow">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div class="info-container" @click="openCup()">
                  <div class="info-image-container">
                    <img class="info-background-image" alt="EBCUP"
                         v-lazyimg="'https://ebikeworldfederation.com/images/infobar/EBWF_Website_Contentbild_02.jpg'">
                    <img class="info-logo-image" alt="EBCUP"
                         v-lazyimg="'https://ebikeworldfederation.com/images/EBCUP_LOGO.svg'">
                  </div>
                  <div class="info-text-container">
                    <div class="info-text-container-title">
                      {{$t('home.project.cup.title')}}
                    </div>
                    <div class="info-text-container-location">
                      <img class="info-text-container-location-flag" alt="Flag"
                           v-lazyimg="'https://ebikeworldfederation.com/images/locations/EBWF_Austria.svg'">
                      {{$t('home.project.cup.location')}}
                    </div>
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve" class="info-text-container-arrow">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div class="info-container" @click="goToMember()">
                  <img class="info-background-image" alt="Member"
                       v-lazyimg="'https://ebikeworldfederation.com/images/infobar/new/EBWF_Mitgliedschaft_Contentbild_Schutzbrief_01.jpg'">
                  <div class="info-container-title info-container-title-white" v-html="$t('home.becomeMemberNow')"/>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div class="info-container" @click="goToShop()">
                  <img class="info-background-image" alt="Shop"
                       v-lazyimg="'https://ebikeworldfederation.com/images/infobar/new/EBWF_Mitgliedschaft_Contentbild_Hoodies.jpg'">
                  <div class="info-container-title info-container-title-dark">
                    EBWF Shop
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>

<script>
  import {mdiChevronRight,mdiCalendarBlankOutline} from '@mdi/js'
  export default {
    name: 'info-bar',
    data() {
      return {
        icons: {
          right: mdiChevronRight,
          calendar: mdiCalendarBlankOutline,
        },
      };
    },
    methods: {
      openCup() {
        window.open('https://ebikecup.com', '_blank');
      },
      openWM() {
        window.open('https://ebikewm.com', '_blank');
      },
      goToMember() {
        this.$router.push({ name: 'member' });
      },
      goToShop() {
        window.open('https://shop.ebikeworldfederation.com/', '_blank');
        // this.$router.push({ name: 'shop' });
      }
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";
  .home-info-bar-container {
    font-family: $default-font;
    position: relative;
    background: linear-gradient(0deg, white 50%, $primary 50%);

    @include MQ(XS) {
      background: linear-gradient(0deg, white 90%, $primary 10%);
    }
    @include MQ(S) {
      background: linear-gradient(0deg, white 75%, $primary 25%);
    }
    .info-container {
      border-radius: 8px;
      height: 156px;
      background-color: white;
      box-shadow: 0 3px 6px #00000029;
      position: relative;
      cursor: pointer;
      @include MQ(XL) {
        height: 185px;
      }
      .info-container-title {
        position: relative;
        font-style: italic;
        font-weight: 600;
        font-size: 25px;
        text-align: left;
        padding: 10px;
        &.info-container-title-white {
          color: white;
        }
        &.info-container-title-dark {
          color: $primary-text;
        }
      }
      .info-background-image {
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
      }
      .info-image-container {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        position: relative;
        height: 88px;
        @include MQ(XL) {
          height: 115px;
        }
        .info-background-image {
          border-radius: 8px 8px 0 0;
        }
        .info-logo-image {
          height: 50px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          @include MQ(XL) {
            height: 70px;
          }
        }
      }
      .info-text-container {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        position: relative;
        height: 68px;
        padding: 5px;
        text-align: left;
        @include MQ(XL) {
          height: 70px;
        }
        .info-text-container-title {
          font-style: italic;
          font-weight: 600;
        }
        .info-text-container-date {
          font-size: 12px;
          font-weight: 400;
          svg {
            height: 15px;
            width: 15px;
            margin-top: -3px;
            margin-left: -2px;
          }
        }
        .info-text-container-location {
          font-size: 12px;
          font-weight: 400;
          .info-text-container-location-flag {
            margin-right: 2px;
          }
        }
        .info-text-container-arrow {
          height: 15px;
          position: absolute;
          right: 15px;
          bottom: 10px;
        }
      }
    }


    /********************** Infinite Slider (Mobile) **********************/
    .outer-slider-wrapper {
      height: 100%;
      width: 100%;
      overflow-y: hidden;

      .scrolling-wrapper-flexbox {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;

        .info-bar-slider-item {
          min-width: 350px;
          margin-right: 10px;

          &.guiness-item {
            @include MQ(XS) {
              min-width: 300px;
            }
            @include MQ(S) {
              min-width: 330px;
            }
          }
        }

        ::-webkit-scrollbar {
          display: none;
          width: 0;  /* remove scrollbar space */
          background: transparent;  /* optional: just make scrollbar invisible */
        }
        &::-webkit-scrollbar {
          display: none;
          width: 0;  /* remove scrollbar space */
          background: transparent;  /* optional: just make scrollbar invisible */
        }
      }
    }
  }
</style>
